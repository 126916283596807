import {
    Group,
    Paper,
    Text,
    Stack,
    Badge,
    RingProgress
} from '@mantine/core';

const Card = ({ title, Icon, count, children, isStat, progress }) => {
    let progressColor;

    if(progress) {
        if(progress >= 90) {
            progressColor = "green";
        } else if(progress >= 80) {
            progressColor = "yellow";
        } else if(progress >= 70) {
            progressColor = "orange";
        } else {
            progressColor = "red";
        }
    }

    return(
        <Paper withBorder p="md" radius="md" key={title}>
            {
                progress ?
                    <Group className="h-100">
                        <RingProgress
                            size={80}
                            roundCaps
                            thickness={8}
                            sections={[{ value: progress, color: progressColor }]}
                            // label={
                            // <Center>
                            //     <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
                            // </Center>
                            // }
                        />

                        <div>
                            <Text c="dimmed" size="sm" tt="uppercase" fw={700}>
                                {title}
                            </Text>
                            {children}
                        </div>
                    </Group>
                :
                    <Stack className="h-100" gap="0">
                        <Group justify="space-between" wrap="nowrap" className={ !isStat ? "tif-mb-20" : "" } align="center">
                            <Group gap="5" align="center">
                                { count ? <Badge color="gray" size="md" variant="default">{count}</Badge> : <></> }
                                <Text size="sm" c="dimmed" className="title">
                                    {title}
                                </Text>
                            </Group>
                            <Icon className="icon" size="1.5rem" stroke={1.5} />
                        </Group>

                        {children}
                    </Stack>
            }
        </Paper>
    );
};

export default Card;