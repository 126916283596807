import { useEffect, useState } from 'react';
import { Avatar, Table, Group, Text, rem, Skeleton, Input, CloseButton, Button, Popover, TextInput, Select } from '@mantine/core';
import {
    IconChevronRight,
    IconSearch,
    IconPlus
} from '@tabler/icons-react';
import { getAllClients, createClient } from '../../data/dataApi';
import { useUserState } from '../../views/components/UserStateContext';
import ContentWrapper from '../../views/components/ContentWrapper';
import { useNavigate } from 'react-router';
import { projectTypes, showSuccessNotification, showUnexpectedErrorNotification } from '../../views/helpers';

const Clients = () => {
    const { clients, setClients } = useUserState();
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState(clients);
    const [newClientName, setNewClientName] = useState('');
    const [newClientNameError, setNewClientNameError] = useState('');
    const [newClientKey, setNewClientKey] = useState('');
    const [newClientKeyError, setNewClientKeyError] = useState('');
    const [newClientType, setNewClientType] = useState('');
    const [newClientTypeError, setNewClientTypeError] = useState('');
    const [newClientLoading, setNewClientLoading] = useState(false);

    const navigate = useNavigate();

    const handleCreateClient = async (name, key, type) => {
        setNewClientNameError('');
        setNewClientKeyError('');
        setNewClientTypeError('');
        setNewClientLoading(true);

        if(!name) {
            setNewClientNameError('Name is required');
        }

        if(!key) {
            setNewClientKeyError('Key is required');
        }

        if(!type) {
            setNewClientTypeError('Type is required');
        }

        if(name && key && type) {
            const response = await createClient(name, key, type);

            if(response.success) {
                const refreshClients = prevClients => [...prevClients, response.data.rows[0]].sort((a, b) => a.name.localeCompare(b.name));
                setClients(prevState => refreshClients(prevState));
                setSearchResults(prevState => refreshClients(prevState));
                showSuccessNotification('Client created successfully');
                navigate(`/clients/${ response.data.rows[0].id }`);
            } else {
                showUnexpectedErrorNotification();
            }
        }

        setNewClientLoading(false);
    };

    useEffect(() => {
        (async () => {
            const clientsResponse = await getAllClients();
            
            if(clientsResponse.success) {
                const allClients = clientsResponse.data.rows.sort((a, b) => a.name.localeCompare(b.name));
                setClients(allClients);
                setSearchResults(allClients);
            }
        })();
    }, []);

    useEffect(() => {
        if(search) {
            setSearchResults(clients.filter(client => client.name.toLowerCase().includes(search.toLowerCase())));
        } else {
            setSearchResults(clients);
        }
    }, [search]);

    return (
        <ContentWrapper className="scrollable">
            <div id="clients" className="wrapper">
                {
                    searchResults !== null ?
                        <>
                            <Group align="center" justify="space-between" mb="15px">
                                <Text size="sm" className="tif-ls-50 text-uppercase">
                                    Clients
                                </Text>
                                <Popover width={400} trapFocus position="bottom" withArrow shadow="md" closeOnClickOutside={false}>
                                    <Popover.Target>
                                        <Button leftSection={<IconPlus size={16}/>}>Add Client</Button>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <TextInput label="Name" placeholder="Name" mb="md" value={newClientName} onChange={e => setNewClientName(e.currentTarget.value)} error={newClientNameError} />
                                        <TextInput label="Key" placeholder="Key" mb="md" value={newClientKey} onChange={e => setNewClientKey(e.currentTarget.value)} error={newClientKeyError} />
                                        <Select label="Type" placeholder="Client Type" data={projectTypes} mb="md" value={newClientType} onChange={setNewClientType} error={newClientTypeError} />
                                        <Button variant="outline" onClick={() => handleCreateClient(newClientName, newClientKey, newClientType)} loading={newClientLoading}>Save</Button>
                                    </Popover.Dropdown>
                                </Popover>
                            </Group>
                            <Input
                                className="search-input"
                                placeholder="Search Clients"
                                leftSection={<IconSearch size={24} />}
                                value={search}
                                onChange={e => setSearch(e.currentTarget.value)}
                                rightSectionPointerEvents="all"
                                mt="md"
                                mb="md"
                                variant="filled"
                                size="xl"
                                rightSection={
                                    <CloseButton
                                        aria-label="Search Clients"
                                        onClick={() => setSearch('')}
                                        style={{ display: search ? undefined : 'none' }}
                                        size="xl"
                                    />
                                }
                            />
                            <Table.ScrollContainer minWidth={800}>
                                <Table verticalSpacing="md">
                                    <Table.Tbody>
                                        {
                                            searchResults.map(client => (
                                                <Table.Tr key={client.name} role="button">
                                                    <Table.Td onClick={() => navigate(`/clients/${ client.id }`)}>
                                                        <Group gap="sm">
                                                            <Avatar size={40} src={client.avatar_url} radius={40} />
                                                            <div>
                                                                <Text fz="sm" fw={500}>
                                                                    {client.name}
                                                                </Text>
                                                                <Text c="dimmed" fz="xs">
                                                                    {client.type}
                                                                </Text>
                                                            </div>
                                                            <div className="tif-ml-auto">
                                                                <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
                                                            </div>
                                                        </Group>
                                                    </Table.Td>
                                                </Table.Tr>
                                            ))
                                        }

                                    </Table.Tbody>
                                </Table>
                            </Table.ScrollContainer>
                        </>
                    :
                        <>
                            <Skeleton height={20} width={120} radius="md" className="tif-mb-15" />
                            <Skeleton height={805} radius="md" />
                        </>
                }
            </div>
        </ContentWrapper>
    );
};

export default Clients;
