import { useEffect, useState, useRef } from "react";
import { Accordion, Button, Group, Popover, Text } from "@mantine/core";
import EditableField from "./EditableField";
import FieldGroup from "./FieldGroup";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { deleteDefaultFieldGroup } from "../../data/dataApi";
import { showSuccessNotification, showUnexpectedErrorNotification } from "../helpers";

const DefaultFieldGroup = ({ group, position, onGroupUpdate }) => {
    const [fields, setFields] = useState(group.fields);
    const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);

    const prevFieldsRef = useRef();

    const saveFieldGroup = data => {
        const { id: groupId, name } = data;

        onGroupUpdate(prevState => {
            const newGroups = [...prevState];
            newGroups[position].id = groupId;
            newGroups[position].name = name;
            newGroups[position].newGroup = false;
            return newGroups;
        });
    };

    const handleDeleteFieldGroup = async () => {
        const response = await deleteDefaultFieldGroup(group.id);

        if(response.success) {
            onGroupUpdate(prevState => prevState.filter(g => g.id !== group.id));
            setFields(prevState => prevState.filter((_, index) => index !== position));
            showSuccessNotification("Field group deleted successfully.");
            setDeletePopoverOpen(false);
        } else {
            showUnexpectedErrorNotification();
        }
    }

    useEffect(() => {
        onGroupUpdate(prevState => {
            const newGroups = [...prevState];
            newGroups[position].fields = fields;

            const fieldsChanged = prevFieldsRef.current ? JSON.stringify(prevFieldsRef.current) !== JSON.stringify(fields) : false;
            newGroups[position].edited = fieldsChanged;

            return newGroups;
        });

        prevFieldsRef.current = fields;
    }, [fields, position]);

    return (
        <Accordion.Item value={`group_${group.id}`}>
            <Accordion.Control>
                <EditableField id={group.id} element="span" name="field-groups_name" className="tif-mb-0" size="xl" focus={group.newGroup} newGroup={group.newGroup} position={position} onSave={saveFieldGroup}>{group.name || '\u00A0'}</EditableField>
            </Accordion.Control>
            <Accordion.Panel>
                {
                    fields.map((field, fieldIndex) => (
                        <FieldGroup field={field} onChange={setFields} position={fieldIndex} key={fieldIndex} />
                    ))
                }

                <Group>
                    <Button variant="light" className="flex-grow-1" onClick={() => setFields(prevState => ([...prevState, { name: "", type: "", newField: true }]))}>Add Field</Button>
                    <Popover withArrow width={315} opened={deletePopoverOpen} onChange={setDeletePopoverOpen}>
                        <Popover.Target onClick={() => setDeletePopoverOpen(true)}>
                            <Button variant="light" color="red"><IconTrash size="18px" /></Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Group wrap="nowrap">
                                <Text size="sm">Are you sure you want to delete this field group?</Text>
                                <Button.Group>
                                    <Button variant="subtle" size="xs" onClick={() => setDeletePopoverOpen(false)}><IconX /></Button>
                                    <Button variant="subtle" color="red" size="xs" onClick={handleDeleteFieldGroup}><IconCheck /></Button>
                                </Button.Group>
                            </Group>
                        </Popover.Dropdown>
                    </Popover>
                </Group>
            </Accordion.Panel>
        </Accordion.Item>
    );
};

export default DefaultFieldGroup;