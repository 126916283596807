import { Group, Badge, Avatar, Tooltip, useComputedColorScheme } from "@mantine/core";
import { formatUserPTOMessage, isCurrentMonthAndYear } from "../helpers";
import { useUserState } from "./UserStateContext";

const PTOCalendarDay = ({ day, data }) => {
    const { statsDate } = useUserState();
    const now = statsDate;
    const year = now.getFullYear();
    const month = now.getMonth();
    const todayDate = now.getDate();

    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    const isWeekend = day => {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        return dayOfWeek === 0 || dayOfWeek === 6;
    };

    const isToday = day => day === todayDate;

    let color;

    if(computedColorScheme === "dark") {
         if(isWeekend(day)) {
            color = "var(--mantine-color-dark-4)";
        } else {
            color = "var(--mantine-color-dimmed)";
        }
    } else if (computedColorScheme === "light") {
        if(isWeekend(day)) {
            color = "var(--mantine-color-gray-4)";
        } else {
            color = "var(--mantine-color-dimmed)";
        }
    }

    if(isToday(day) && isCurrentMonthAndYear(now)) {
        color = "green";
    }

    const isDay = (day, dateStrings) => {
        return dateStrings.some(({date}) => {
            const dateDay = parseInt(date.split('/')[1], 10);
            return day === dateDay;
        });
    };

    return (
        <Group className="flex-column flex-grow-0">
            {
                data?.filter(user => isDay(day, user.pto)).length ?
                    <div className="month-users-time-off">
                        <Tooltip color="var(--mantine-color-body)" arrowSize={10} p="10px" position="bottom" withArrow label={
                            <Group className="flex-column" gap="5px">
                                {
                                    data?.filter(user => isDay(day, user.pto)).map((user, index) => (
                                        <Badge style={{ textTransform: "unset" }} className="user-time-off-badge" size="lg" color="gray" fw="normal" variant="light" key={index} leftSection={<Avatar size="xs" src={user.avatarUrl} alt={user.name} />}>
                                            { formatUserPTOMessage(user) }
                                        </Badge>
                                    ))
                                }
                            </Group>
                        }>
                            <Badge key={day} size="xl" color={color} fw="normal" mb="5px" circle>{day}</Badge>
                        </Tooltip>
                        <Group className="user-pto-counts-wrapper" gap="5px" wrap="wrap" justify="center">
                            {
                                data?.filter(user => isDay(day, user.pto)).map((_, index) => (
                                    <div className="user-pto-count" key={index} />
                                ))
                            }
                        </Group>
                    </div>
                :
                    <Badge key={day} size="xl" color={color} fw="normal" circle>{day}</Badge>
            }

        </Group>
    );
};

export default PTOCalendarDay;