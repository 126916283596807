import { useEffect, useState } from 'react';
import { Modal, Box, Group } from '@mantine/core';
import { clientsSettingsLinks } from '../helpers';
import DefaultFieldGroups from './DefaultFieldGroups';

const ClientsSettingsModal = ({ opened, close }) => {
    const initialTab = clientsSettingsLinks[0];

    const [activeTab, setActiveTab] = useState(initialTab);

    const items = clientsSettingsLinks.map((item) => (
        <Box
            component="a"
            onClick={() => setActiveTab(item)}
            key={item.label}
            className={`link position-relative ${activeTab.link === item.link && "linkActive"}${ item.order > 1 ? " sublink" : "" }`}
            style={{ paddingLeft: `calc(${item?.order || 1} * var(--mantine-spacing-md))` }}
            mb="5px"
        >
            {item.label}
        </Box>
    ));

    return (
        <Modal.Root opened={opened} onClose={close} className="clients-settings-modal" size="xl">
            <Modal.Overlay blur="3" />
            <Modal.Content>
                <Group gap="25px" align="stretch" className="h-100">
                    <div className="user-settings-tabs">
                        <Box pl="16px" py="16px">
                            {items}
                        </Box>
                    </div>
                    <Modal.Body className="d-flex flex-column flex-grow-1 tif-pl-0">
                        <Modal.Header className="tif-px-0">
                            <Modal.Title>{activeTab.label}</Modal.Title>
                            <Modal.CloseButton />
                        </Modal.Header>
                        {
                            activeTab.link === "#global-field-groups" ?
                                <DefaultFieldGroups />
                            :
                                <></>
                        }

                        {/* {
                            clientsSettingsLinks.map(tab => (
                                activeTab.type === tab.type ?
                                    <DefaultFieldGroups type={tab.type} />
                                :
                                    null
                            ))
                        } */}
                    </Modal.Body>
                </Group>
            </Modal.Content>
        </Modal.Root>
    );
};

export default ClientsSettingsModal;