import { useEffect, useState } from 'react';
import ContentWrapper from '../../views/components/ContentWrapper';
import { Breadcrumbs, Text, ActionIcon, Group, Paper, Accordion, Avatar, Anchor, SimpleGrid, Alert } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getClient, getClientDetails } from '../../data/dataApi';
import EditableField from '../../views/components/EditableField';
import { projectTypes, reformatClientDetails, toIdentifier, hosts, listTickets } from '../../views/helpers';
import ClientSettingsModal from '../../views/components/ClientSettingsModal';
import { useDisclosure } from '@mantine/hooks';
import { IconListSearch, IconSettings, IconCoin, IconReceipt2, IconCalendarDollar, IconCalendarClock, IconCalendarUp, IconInfoCircle, IconClockStop, IconClockPlay, IconClockQuestion, IconClockSearch, IconAlertTriangle, IconFlame } from '@tabler/icons-react';
import Element from '../../views/components/Element';
import { useUserState } from '../../views/components/UserStateContext';
import Card from '../../views/components/Card';

const SingleClient = () => {
    const { clientDetails, setClientDetails } = useUserState();
    const [client, setClient] = useState();
    const [clientTicketCounts, setClientTicketCounts] = useState();
    const [clientTicketOverview, setClientTicketOverview] = useState();

    const [clientSettingsModalOpen, { open: onClientSettingsModalOpen, close: onClientSettingsModalClose }] = useDisclosure(false);

    const items = [
        { title: 'Clients', href: '/clients' },
        { title: client?.name, href: `/clients/${client?.id}` },
    ].map((item, index) => (
        <Link to={item.href} key={index} className="text-decoration-none tif-color-hotel">
            {item.title}
        </Link>
    ));

    const { id } = useParams();

    const navigate = useNavigate();

    const getFieldValue = (values = [], fieldName = "") => {
        const field = values.find(value => value.field_name === toIdentifier(fieldName));

        if (field) {
            return field.field_value;
        }

        return null;
    };

    const getFieldValueId = (values = [], fieldName = "") => {
        const field = values.find(value => value.field_name === toIdentifier(fieldName));

        if (field) {
            return field.field_value_id;
        }

        return null;
    };

    const handleValueChange = (groupId, fieldName, value) => {
        setClientDetails(prevState => {
            const newState = [...prevState];
            const groupIndex = newState.findIndex(group => group.field_group_id === groupId);
            const fieldIndex = newState[groupIndex].fields_values.findIndex(field => field.field_name === toIdentifier(fieldName));
            return newState.map((group, index) => {
                if (index === groupIndex) {
                    return {
                        ...group,
                        fields_values: group.fields_values.map((field, index) => {
                            if (index === fieldIndex) {
                                return {
                                    ...field,
                                    field_value: value
                                };
                            }
                            return field;
                        })
                    };
                }
                return group;
            });
        });
    };

    useEffect(() => {
        (async () => {
            const clientResponse = await getClient(id);
            const clientDetailsResponse = await getClientDetails(id);

            if (clientResponse.success && clientResponse.data.rows.length && clientDetailsResponse.success) {
                setClient(clientResponse.data.rows[0]);
                if (clientResponse.stats) {
                    const { onHold, open, inProgress, customerReview, highPriority, whatsHot } = clientResponse.stats;
                    setClientTicketCounts(clientResponse.stats.ticketCount);
                    setClientTicketOverview({ onHold, open, inProgress, customerReview, highPriority, whatsHot });
                }
                setClientDetails(reformatClientDetails(clientDetailsResponse.data.rows));
            } else {
                navigate('/clients');
            }
        })();
    }, []);

    return (
        <ContentWrapper className="scrollable">
            <div id="single-client" className="wrapper">
                {
                    client ?
                        <>
                            <Group justify="space-between">
                                <Breadcrumbs className="tif-font-xs text-uppercase" mb={30}>{items}</Breadcrumbs>
                                <ActionIcon variant="subtle" size="lg" onClick={() => onClientSettingsModalOpen(true)}>
                                    <IconSettings style={{ width: 24, height: 24 }} stroke={1.5} />
                                </ActionIcon>
                            </Group>
                            <EditableField element="h1" name="clients_name" id={client.id} className="tif-mb-0" size="xl">{client.name}</EditableField>
                            <EditableField element={Text} name="clients_type" id={client.id} className="tif-mb-0" choices={projectTypes} onSave={value => setClient(prevState => ({ ...prevState, type: value }))}>{client.type}</EditableField>
                            <Group mt="30" align="start" wrap="nowrap">
                                <Paper withBorder p="md" radius="md" className="client-sidebar">
                                    <Group justify="space-between" mb="15">
                                        <Text c="dimmed" fz="xs" className="text-uppercase">
                                            Client Details
                                        </Text>
                                        <IconListSearch size="1.4rem" className="icon" stroke={1.5} />
                                    </Group>
                                    <Accordion multiple variant="separated" defaultValue={["general"]}>
                                        <Accordion.Item value="general">
                                            <Accordion.Control>
                                                General
                                            </Accordion.Control>
                                            <Accordion.Panel>
                                                {
                                                    client ?
                                                        <>
                                                            {
                                                                client.project_lead &&
                                                                <Group gap="sm" mb="15">
                                                                    <Avatar size={40} src={client.project_lead_avatar_url} radius={40} />
                                                                    <div>
                                                                        <Text fz="sm" fw={500}>
                                                                            {client.project_lead}
                                                                        </Text>
                                                                        <Text c="dimmed" fz="xs">
                                                                            Project Lead
                                                                        </Text>
                                                                    </div>
                                                                </Group>
                                                            }
                                                            {
                                                                client.design_lead &&
                                                                <Group gap="sm" mb="15">
                                                                    <Avatar size={40} src={client.design_lead_avatar_url} radius={40} />
                                                                    <div>
                                                                        <Text fz="sm" fw={500}>
                                                                            {client.design_lead}
                                                                        </Text>
                                                                        <Text c="dimmed" fz="xs">
                                                                            Design Lead
                                                                        </Text>
                                                                    </div>
                                                                </Group>
                                                            }
                                                            {
                                                                client.tech_lead &&
                                                                <Group gap="sm">
                                                                    <Avatar size={40} src={client.tech_lead_avatar_url} radius={40} />
                                                                    <div>
                                                                        <Text fz="sm" fw={500}>
                                                                            {client.tech_lead}
                                                                        </Text>
                                                                        <Text c="dimmed" fz="xs">
                                                                            Tech Lead
                                                                        </Text>
                                                                    </div>
                                                                </Group>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Website URL</Text>
                                                <EditableField element="a" name="clients_website-url" id={id} type="URL" value={client.website_url} className="tif-mb-0" size="xl" required={false} onSave={website_url => setClient(prevState => ({ ...prevState, website_url }))} href={client.website_url} target="_blank">
                                                    {
                                                        client.website_url ?
                                                            <Anchor href={client.website_url} target="_blank">{client.website_url}</Anchor>
                                                            :
                                                            <Text c="dimmed" size="md">(empty)</Text>
                                                    }
                                                </EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contact Name</Text>
                                                <EditableField element={Text} name="clients_contact-name" id={id} value={client.contact_name} className="tif-mb-0" size="md" required={false} onSave={contact_name => setClient(prevState => ({ ...prevState, contact_name }))}>
                                                    <Text c="dimmed" size="md">{client.contact_name || "(empty)"}</Text>
                                                </EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contact Email</Text>
                                                <EditableField element="a" name="clients_contact-email" type="Email" id={id} value={client.contact_email} className="tif-mb-0" size="md" required={false} onSave={contact_email => setClient(prevState => ({ ...prevState, contact_email }))} href={client.contact_email ? `mailto:${client.contact_email}` : null}>
                                                    {
                                                        client.contact_email ?
                                                            <Anchor href={client.contact_email ? `mailto:${client.contact_email}` : null} target="_blank">{client.contact_email}</Anchor>
                                                            :
                                                            <Text c="dimmed" size="md">(empty)</Text>
                                                    }
                                                </EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Host</Text>
                                                <EditableField element={Text} name="clients_host" id={client.id} className="tif-mb-0" choices={hosts} onSave={value => setClient(prevState => ({ ...prevState, host: value }))} allowDeselect required={false}>{client.host || "(empty)"}</EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contract Start Date</Text>
                                                <EditableField element={Text} name="clients_contract-start-date" type="Date Picker" id={id} value={client.contract_start_date ? new Date(client.contract_start_date) : null} className="tif-mb-0" size="md" required={false} onSave={contract_start_date => setClient(prevState => ({ ...prevState, contract_start_date }))} clearable maxDate={client.contract_end_date ? new Date(client.contract_end_date) : null}>
                                                    <Text c="dimmed" size="md">{client.contract_start_date || "(empty)"}</Text>
                                                </EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contract End Date</Text>
                                                <EditableField element={Text} name="clients_contract-end-date" type="Date Picker" id={id} value={client.contract_end_date ? new Date(client.contract_end_date) : null} className="tif-mb-0" size="md" required={false} onSave={contract_end_date => setClient(prevState => ({ ...prevState, contract_end_date }))} clearable minDate={client.contract_start_date ? new Date(client.contract_start_date) : null}>
                                                    <Text c="dimmed" size="md">{client.contract_end_date || "(empty)"}</Text>
                                                </EditableField>
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        {
                                            clientDetails.map((detail, index) => (
                                                <Accordion.Item value={detail.field_group_name} key={index}>
                                                    <Accordion.Control>
                                                        {
                                                            detail.field_group_global ?
                                                                <>{detail.field_group_name}</>
                                                                :
                                                                <EditableField id={detail.field_group_id} element="span" name="field-groups_name" className="tif-mb-0" size="xl">{detail.field_group_name}</EditableField>
                                                        }
                                                    </Accordion.Control>
                                                    <Accordion.Panel>
                                                        {
                                                            detail.field_group_fields.map((field, index) => (
                                                                <Element id={getFieldValueId(detail.fields_values, field.name)} name={field.name} type={field.type} value={getFieldValue(detail.fields_values, field.name)} position={index} groupId={detail.field_group_id} onChange={handleValueChange} />
                                                            ))
                                                        }
                                                    </Accordion.Panel>
                                                </Accordion.Item>
                                            ))
                                        }
                                    </Accordion>
                                </Paper>
                                <div className="flex-grow-1">
                                    <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-20">
                                        <Card title="Total Budget" Icon={IconCoin}>
                                            <EditableField element={Text} type="Number" className="value tif-font-xl tif-mt-25 tif-mb-10" name="clients_total-budget" id={client.id} required={false}>{client.total_budget || 0}</EditableField>
                                        </Card>
                                        <Card title="Total Billed" Icon={IconReceipt2}>
                                            {
                                                client.contract_start_date && client.contract_end_date ?
                                                    <p className="value tif-font-xl tif-mt-25 tif-mb-10">TBD</p>
                                                    :
                                                    <Alert title="No contract dates set" icon={<IconInfoCircle />} />
                                            }
                                        </Card>
                                        <Card title="Total Remaining" Icon={IconCalendarDollar}>
                                            <p className="value tif-font-xl tif-mt-25 tif-mb-10">TBD</p>
                                        </Card>
                                        <Card title="Months Remaining" Icon={IconCalendarClock}>
                                            <p className="value tif-font-xl tif-mt-25 tif-mb-10">TBD</p>
                                        </Card>
                                        <Card title="Billed Last Month" Icon={IconCalendarUp}>
                                            <p className="value tif-font-xl tif-mt-25 tif-mb-10">TBD</p>
                                        </Card>
                                        <Card title="Avg. Hours Per Month Remaining" Icon={IconCalendarDollar}>
                                            <p className="value tif-font-xl tif-mt-25 tif-mb-10">TBD</p>
                                        </Card>
                                    </SimpleGrid>

                                    {
                                        clientTicketCounts &&
                                        <div className="stat-panel tif-mb-20">
                                            <div className="stat-container">
                                                <Text className="stat-count">{clientTicketCounts[0].total}</Text>
                                                <Text className="stat-title">On Hold</Text>
                                            </div>
                                            <div className="stat-container">
                                                <Text className="stat-count">{clientTicketCounts[1].total}</Text>
                                                <Text className="stat-title">Open</Text>
                                            </div>
                                            <div className="stat-container">
                                                <Text className="stat-count">{clientTicketCounts[2].total}</Text>
                                                <Text className="stat-title">In Progress</Text>
                                            </div>
                                            <div className="stat-container">
                                                <Text className="stat-count">{clientTicketCounts[3].total}</Text>
                                                <Text className="stat-title">Customer Review</Text>
                                            </div>
                                        </div>
                                    }

                                    <SimpleGrid cols={{ base: 1, md: 3 }} className="tif-mb-40">
                                        <Card title="On Hold" Icon={IconClockStop} count={clientTicketOverview.onHold[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.onHold[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=account%20%3D%20"${client.name}"%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20%3D%20"On%20Hold"%20ORDER%20BY%20updated%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="Open" Icon={IconClockQuestion} count={clientTicketOverview.open[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.open[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=account%20%3D%20"${client.name}"%20AND%20workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29%20ORDER%20BY%20workratio%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="In Progress" Icon={IconClockPlay} count={clientTicketOverview.inProgress[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.inProgress[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=account%20%3D%20"${client.name}"%20AND%20statuscategory%20%3D%20"In%20Progress"%20AND%20status%20%21%3D%20"Client%20Review"%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="Customer Review" Icon={IconClockSearch} count={clientTicketOverview.customerReview[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.customerReview[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=account%20%3D%20"${client.name}"%20AND%20status%20%3D%20"Client%20Review"%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="High Priority" Icon={IconAlertTriangle} count={clientTicketOverview.highPriority[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.highPriority[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=account%20%3D%20"${client.name}"%20AND%20priority%20IN%20%28High%2C%20Highest%29%20ORDER%20BY%20priority%20DESC%2C%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="What's Hot" Icon={IconFlame} count={clientTicketOverview.whatsHot[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.whatsHot[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=account%20%3D%20"${client.name}"%20AND%20workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29%20ORDER%20BY%20workratio%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                    </SimpleGrid>
                                </div>
                            </Group>
                        </>
                        :
                        <p>Setup loading skeleton</p>
                }
            </div>
            <ClientSettingsModal
                opened={clientSettingsModalOpen}
                close={onClientSettingsModalClose}
                client={client}
                id={id}
                onClientChange={setClient}
                onConfirmationModalOpen={onClientSettingsModalOpen}
            />
        </ContentWrapper>
    );
};

export default SingleClient;
