import { useEffect, useState } from 'react';
import { Modal, Group, Avatar, Text, Box, Divider } from '@mantine/core';
import ClientGeneralSettings from './ClientGeneralSettings';
import CreateFieldGroupSettings from './CreateFieldGroupSettings';
import { getClientDetails, getFieldGroupTabs } from '../../data/dataApi';
import FieldGroupSettings from './FieldGroupSettings';
import { IconPlus } from '@tabler/icons-react';
import { useUserState } from './UserStateContext';
import { reformatClientDetails } from '../helpers';

const ClientSettingsModal = ({ opened, close, client, id, onClientChange }) => {
    const { defaultFieldGroups, setClientDetails } = useUserState();
    const initialTab = { label: 'General', link: '#general' };
    const createFieldGroupTab = { label: 'Create', link: '#create-field-group' };
    const [activeTab, setActiveTab] = useState(initialTab);
    const [tabs, setTabs] = useState([]);

    const items = tabs.map((item) => (
        <Box
            component="a"
            onClick={() => setActiveTab(item)}
            key={item.label}
            className={`link position-relative ${ item?.dark ? "dark" : "" } ${activeTab.link === item.link ? "linkActive" : "" }${ item.order > 1 ? " sublink" : "" }`}
            style={{ paddingLeft: `calc(${item?.order || 1} * var(--mantine-spacing-md))` }}
            mb="5px"
        >
            {item.label}
        </Box>
    ));

    const handleClientDetailsChange = async () => {
        const response = await getClientDetails(id);

        if(response.success) {
            setClientDetails(reformatClientDetails(response.data.rows));
        }
    };

    useEffect(() => {
        (async () => {
            const response = await getFieldGroupTabs(id);

            if(response.success) {
                setTabs(response.data.rows.map(row => ({ id: row.id, label: row.name, link: `#field-group-${row.id}` })));
            }
        })();
    }, [defaultFieldGroups]);

    return (
        <Modal.Root opened={opened} onClose={close} className="client-settings-modal" size="xl">
            <Modal.Overlay blur="3" />
            <Modal.Content>
                <Group gap="25px" align="stretch" className="h-100" wrap="nowrap">
                    <div className="client-settings-tabs d-flex flex-column flex-shrink-0 position-sticky top-0">
                        {
                            client ?
                                <Group gap="sm" className="avatar-wrapper flex-nowrap tif-mb-20">
                                    <Avatar src={client.avatar_url} size="md" />
                                    <div>
                                        <Text fz="sm" fw={500}>{client.name}</Text>
                                        <Text c="dimmed" fz="xs">
                                            {client.type}
                                        </Text>
                                    </div>
                                </Group>
                            :
                                <></>
                        }
                        <Divider mb="20px" />
                        <Box className="d-flex flex-column h-100" pl="16px">
                            <Box
                                component="a"
                                onClick={() => setActiveTab(initialTab)}
                                key={initialTab.label}
                                className={`link position-relative ${activeTab.link === initialTab.link ? "linkActive" : ""}`}
                                pl="md"
                            >
                                {initialTab.label}
                            </Box>
                            {items}
                            <Box
                                component="a"
                                onClick={() => setActiveTab(createFieldGroupTab)}
                                key={createFieldGroupTab.label}
                                className={`link mt-auto tif-mb-20 position-relative ${activeTab.link === createFieldGroupTab.link ? "linkActive" : ""}`}
                                pl="md"
                            >
                                <IconPlus size={16} className="tif-mr-10" style={{ verticalAlign: "text-bottom" }} />
                                {createFieldGroupTab.label}
                            </Box>
                        </Box>
                    </div>
                    <Modal.Body className="d-flex flex-column flex-grow-1 tif-pl-0">
                        {
                            activeTab &&
                                <>
                                    <Modal.Header className="tif-px-0">
                                            <Modal.Title>
                                                { activeTab.label}
                                            </Modal.Title>
                                        <Modal.CloseButton ml="0" />
                                    </Modal.Header>
                                    {
                                        activeTab.link === "#general" ?
                                            <ClientGeneralSettings client={client} onClientChange={onClientChange} />
                                        :
                                            <></>
                                    }
                                    {
                                        tabs.map(tab => (
                                            activeTab.link === tab.link &&
                                                <FieldGroupSettings key={tab.link} link={tab.link} clientId={id} id={tab.id} onSave={handleClientDetailsChange} />
                                        ))
                                    }
                                    {
                                        activeTab.link === "#create-field-group" ?
                                            <CreateFieldGroupSettings client={client} setTabs={setTabs} />
                                        :
                                            <></>
                                    }
                                </>
                        }
                    </Modal.Body>
                </Group>
            </Modal.Content>
        </Modal.Root>
    );
};

export default ClientSettingsModal;