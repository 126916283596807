import { useState } from 'react';
import { Button, Group, TextInput, Select, Table, Combobox, InputBase, useCombobox, NumberInput, SimpleGrid } from '@mantine/core';
import { saveClientGeneralSettings } from '../../data/dataApi';
import { useUserState } from './UserStateContext';
import { showSuccessNotification, showUnexpectedErrorNotification, projectTypes, hosts } from '../helpers';
import { DatePickerInput } from '@mantine/dates';

const ClientGeneralSettings = ({ client, onClientChange }) => {
    const { users } = useUserState();
    const [clientName, setClientName] = useState(client.name);
    const [clientType, setClientType] = useState(client.type);
    const [projectLead, setProjectLead] = useState(client.project_lead);
    const [designLead, setDesignLead] = useState(client.design_lead);
    const [techLead, setTechLead] = useState(client.tech_lead);
    const [websiteUrl, setWebsiteUrl] = useState(client.website_url);
    const [contactName, setContactName] = useState(client.contact_name);
    const [contactEmail, setContactEmail] = useState(client.contact_email);
    const [hostsData, setHostsData] = useState(hosts);
    const [host, setHost] = useState(client.host);
    const [hostSearch, setHostSearch] = useState(client.host);
    const [totalBudget, setTotalBudget] = useState(client.total_budget);
    const [contractStartDate, setContractStartDate] = useState(client.contract_start_date ? new Date(client.contract_start_date) : null);
    const [contractEndDate, setContractEndDate] = useState(client.contract_end_date ? new Date(client.contract_end_date) : null);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const exactHostMatch = hostsData.some((item) => item === hostSearch);
    const filteredHosts = exactHostMatch
        ? hostsData
        : hostSearch ? hostsData.filter((item) => item.toLowerCase().includes(hostSearch.toLowerCase().trim())) : [];

    const hostsOptions = filteredHosts.map((item) => (
        <Combobox.Option value={item} key={item}>
        {item}
        </Combobox.Option>
    ));

    const handleSave = async () => {
        const response = await saveClientGeneralSettings(client.id, {
            clientName,
            clientType,
            contactName,
            contactEmail,
            host,
            projectLead,
            designLead,
            techLead,
            websiteUrl,
            totalBudget,
            contractStartDate: contractStartDate ? new Date(contractStartDate.setHours(0, 0, 0, 0)) : null,
            contractEndDate: contractEndDate ? new Date(contractEndDate.setHours(0, 0, 0, 0)) : null,
        });

        if (response.success) {
            onClientChange(response.data.rows[0]);

            showSuccessNotification("Client settings saved successfully.");
        } else {
            showUnexpectedErrorNotification("There was an error saving the client settings. Please try again.");
        }
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <TextInput
                label="Name"
                defaultValue={client.name}
                value={clientName}
                className="tif-mb-20"
                onChange={e => setClientName(e.target.value)}
            />

            <Select
                value={clientType}
                data={projectTypes}
                onChange={value => setClientType(value)}
                allowDeselect={false}
                label="Client Type"
                mb="20px"
            />

            <TextInput
                label="Website URL"
                defaultValue={client.website_url}
                value={websiteUrl}
                className="tif-mb-20"
                onChange={e => setWebsiteUrl(e.target.value)}
            />

            <TextInput
                label="Contact Name"
                defaultValue={client.contact_name}
                value={contactName}
                className="tif-mb-20"
                onChange={e => setContactName(e.target.value)}
            />

            <TextInput
                label="Contact Email"
                defaultValue={client.contact_email}
                value={contactEmail}
                className="tif-mb-20"
                onChange={e => setContactEmail(e.target.value)}
            />

            <Combobox
                label="Host"
                store={combobox}
                className="tif-mb-20"
                withinPortal={false}
                onOptionSubmit={(val) => {
                    if (val === '$create') {
                        setHostsData((current) => [...current, hostSearch]);
                        setHost(hostSearch);
                    } else {
                        setHost(val);
                        setHostSearch(val);
                    }

                    combobox.closeDropdown();
                }}
            >
                <Combobox.Target>
                    <InputBase
                        rightSection={<Combobox.Chevron />}
                        value={hostSearch}
                        onChange={(event) => {
                            combobox.openDropdown();
                            combobox.updateSelectedOptionIndex();
                            setHostSearch(event.currentTarget.value);
                        }}
                        onClick={() => combobox.openDropdown()}
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => {
                            combobox.closeDropdown();
                            setHostSearch(host || '');
                        }}
                        placeholder="Search host"
                    />
                </Combobox.Target>

                <Combobox.Dropdown>
                    <Combobox.Options>
                        {hostsOptions}
                        {!exactHostMatch && hostSearch && hostSearch.trim().length > 0 && (
                            <Combobox.Option value="$create">+ Create {hostSearch}</Combobox.Option>
                        )}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>

            <h3 className="tif-font-xs text-uppercase tif-mb-0 tif-color-victor">Team</h3>
            <Table className="tif-mb-20">
                <Table.Thead>
                    <Table.Tr style={{ height: 65 }}>
                        <Table.Th className="fw-normal">Project Lead</Table.Th>
                        <Table.Td>
                            <Select
                                placeholder="Project Lead"
                                value={projectLead}
                                data={users.map(user => user.name).sort((a, b) => a.localeCompare(b))}
                                onChange={value => setProjectLead(value)}
                                searchable
                                allowDeselect
                            />
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr style={{ height: 65 }}>
                        <Table.Th className="fw-normal">Design Lead</Table.Th>
                        <Table.Td>
                            <Select
                                placeholder="Design Lead"
                                value={designLead}
                                data={users.map(user => user.name).sort((a, b) => a.localeCompare(b))}
                                onChange={value => setDesignLead(value)}
                                searchable
                                allowDeselect
                            />
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr style={{ height: 65 }} className="border-0">
                        <Table.Th className="fw-normal">Tech Lead</Table.Th>
                        <Table.Td>
                            <Select
                                placeholder="Tech Lead"
                                value={techLead}
                                data={users.map(user => user.name).sort((a, b) => a.localeCompare(b))}
                                onChange={value => setTechLead(value)}
                                searchable
                                allowDeselect
                            />
                        </Table.Td>
                    </Table.Tr>
                </Table.Thead>
            </Table>

            <NumberInput
                label="Total Budget"
                defaultValue={client.total_budget}
                value={totalBudget}
                className="tif-mb-20"
                onChange={setTotalBudget}
            />

            <SimpleGrid cols={{ base: 1, md: 2 }} mb="20px">
                <DatePickerInput
                    label="Contract Start Date"
                    value={contractStartDate}
                    onChange={setContractStartDate}
                    className="flex-grow-1 flex-shrink-0"
                    maxDate={contractEndDate}
                    clearable
                    valueFormat="MM/DD/YYYY"
                />
                <DatePickerInput
                    label="Contract End Date"
                    value={contractEndDate}
                    onChange={setContractEndDate}
                    className="flex-grow-1 flex-shrink-0"
                    minDate={contractStartDate}
                    clearable
                    valueFormat="MM/DD/YYYY"
                />
            </SimpleGrid>

            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline">
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default ClientGeneralSettings;