import { useEffect, useState } from 'react';
import { Button, Group } from '@mantine/core';
import { getFieldGroup, saveFieldGroupValues } from '../../data/dataApi';
import { showSuccessNotification, showUnexpectedErrorNotification, toIdentifier } from '../helpers';
import Field from './Field';
import { useUserState } from './UserStateContext';

const FieldGroupSettings = ({ clientId, id, link, onSave }) => {
    const [fields, setFields] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const [fieldGroupId, setFieldGroupId] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        setLoading(true);
        const response = await saveFieldGroupValues(clientId, fieldGroupId, fieldValues);

        if(response.success) {
            showSuccessNotification("Field group values saved successfully");
        } else {
            showUnexpectedErrorNotification();
        }

        onSave && onSave();

        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            const response = await getFieldGroup(clientId, id);
            
             if(response.success) {
                if(response.data.fieldGroups.length) {
                    setFields(response.data.fieldGroups.map(field => field.fields)[0]);
                    setFieldGroupId(response.data.fieldGroups[0].id);
                    setFieldValues(response.data.fieldValues[0]);
                }   
            }
        })();
    }, [link]);

    return (
        <div className="d-flex flex-column flex-grow-1">
            {
                fields.map((field, index) => 
                    <Field field={field} value={fieldValues.find(item => item.name === toIdentifier(field.name))?.value} onChange={setFieldValues} key={index} />
                )
            }
                
            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline" loading={loading}>
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default FieldGroupSettings;